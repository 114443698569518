import React, { useMemo } from "react";
import cx from "classnames";
import Prando from "prando";

import s from "./Confetti.module.scss";

export const MAX_PARTICLE_RADIUS = 150;
const NUM_PARTICLES = 25;

export type TProps = {
	className?: string;
	x: number;
	y: number;
	fallDistance: number;
	delay?: number;
	isPlaying?: boolean;
};

type TParticleProps = {
	color?: string;
	maxRadius: number;
	angle: number;
	rotation: number;
	scale: number;
	rnd: number;
	delay?: number;
	isPlaying?: boolean;
};

const Particle = ({
	color,
	maxRadius,
	angle,
	rotation,
	scale,
	rnd,
	delay,
	isPlaying,
}: TParticleProps): JSX.Element | null => {
	const radius = isPlaying ? maxRadius : 0;
	const x = Math.cos(angle) * radius - (((rnd * 10) % 1) - 0.5) * 20;
	const y = Math.sin(angle) * radius + (rnd - 0.5) * maxRadius * 0.5;
	const rt = rotation + (isPlaying ? 0 : (rnd - 0.5) * Math.PI * 2);
	const sc = isPlaying ? scale : 0;
	const style = {
		backgroundColor: color,
		transform: `translate(${x}px, ${y}px) scale(${sc}) rotate(${rt}rad) rotate3D(1, 1, 1, ${angle * 73}rad)`,
		opacity: isPlaying ? 0 : 1,
		transitionDelay: `${delay ?? 0}s`,
	};

	return <div className={s.particle} style={style} />;
};

export const Confetti = ({ className, x, y, fallDistance, delay, isPlaying }: TProps): JSX.Element | null => {
	const rng = useMemo(() => {
		return new Prando(new Date().getDay());
	}, []);

	const nx = 0;
	const ny = isPlaying ? MAX_PARTICLE_RADIUS * 1.5 * fallDistance : 0;
	const diam = MAX_PARTICLE_RADIUS * 2;

	const style = {
		// X has no margin
		left: `${x * 100}%`,
		// left: `calc(${MAX_PARTICLE_RADIUS}px + ${x} * (100% - ${diam}px))`,
		// Y has margin equal to radius
		top: `calc(${MAX_PARTICLE_RADIUS}px + ${y} * (100% - ${diam}px))`,
		transform: `translate(${nx}px, ${ny}px)`,
		transitionDelay: `${delay ?? 0}s`,
	};

	const particles = useMemo(() => {
		rng.reset();
		return new Array(NUM_PARTICLES).fill(0).map(() => {
			return {
				color: `rgb(${rng.nextInt(90, 245)}, ${rng.nextInt(50, 225)}, ${rng.nextInt(100, 245)})`,
				radius: Math.sqrt(rng.next()) * MAX_PARTICLE_RADIUS,
				angle: rng.next(0, Math.PI * 2),
				rotation: rng.next(0, Math.PI * 2),
				scale: rng.next(0.8, 1.2),
				rnd: rng.next(0, 1),
			};
		});
	}, [rng]);

	return (
		<div className={cx(s.main, className)} style={style}>
			{particles.map((p, i) => {
				return (
					<Particle
						key={i}
						color={p.color}
						maxRadius={p.radius}
						angle={p.angle}
						rotation={p.rotation}
						scale={p.scale}
						rnd={p.rnd}
						delay={delay}
						isPlaying={isPlaying}
					/>
				);
			})}
		</div>
	);
};
