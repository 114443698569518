import React, { useCallback } from "react";
import cx from "classnames";

import { Button } from "./Button";
import { Stars } from "./Stars";
import {
	shareNatively,
	shareViaClipboard,
	shouldUseAppleShareIcon,
	shouldUseNativeShareForText,
} from "../utils/ShareUtils";
import MaterialIcons from "./MaterialIcons";

import s from "./SlidingMessage.module.scss";

export type TProps = {
	message?: string;
	isVisible?: boolean;
	shareText?: string;
	onAfterShare?: (usedClipboard: boolean) => void;
	showStars?: boolean;
	starsMax?: number;
	starsLeft?: number;
};

export const SlidingMessage = ({
	message,
	isVisible,
	shareText,
	onAfterShare,
	showStars,
	starsMax,
	starsLeft,
}: TProps): JSX.Element | null => {
	const classes = cx(s.main, isVisible ? undefined : s.hidden);

	const handleClickShare = useCallback(() => {
		if (shareText) {
			if (shouldUseNativeShareForText()) {
				shareNatively(shareText).then(() => {
					onAfterShare?.(false);
				});
			} else {
				shareViaClipboard(shareText);
				onAfterShare?.(true);
			}
		}
	}, [shareText, onAfterShare]);

	const opaqueStars = starsLeft ?? 0;
	const transparentStars = (starsMax ?? 0) - opaqueStars;
	const shareIconComponent = shouldUseNativeShareForText()
		? shouldUseAppleShareIcon()
			? MaterialIcons.ShareIos
			: MaterialIcons.Share
		: MaterialIcons.CopyToClipboard;

	return (
		<div className={classes}>
			<div className={s.row}>
				<div className={s.message}>{message}</div>
				{showStars ? <Stars className={s.stars} numOpaque={opaqueStars} numTransparent={transparentStars} /> : null}
			</div>
			<Button className={s.shareButton} icon={shareIconComponent} onClick={handleClickShare} text={"Share"} />
		</div>
	);
};
