import React from "react";
import cx from "classnames";

import { labelToIconComponentTry } from "../data/KeyboardLayout";

import s from "./Key.module.scss";

export type TProps = {
	label: string;
	disabled?: boolean;
	incompatible?: boolean;
	isWrong?: boolean;
	isLarge?: boolean;
	isVeryVeryLarge?: boolean;
	onPress?: () => void;
};

export const Key = ({
	label,
	disabled,
	incompatible,
	isWrong,
	isLarge,
	isVeryVeryLarge,
	onPress,
}: TProps): JSX.Element => {
	const classes = cx(
		s.main,
		incompatible ? s.incompatible : undefined,
		isWrong ? s.wrong : undefined,
		isLarge ? s.large : undefined,
		isVeryVeryLarge ? s.veryVeryLarge : undefined,
	);

	const isDisabled = disabled || incompatible;

	return (
		<button className={classes} onClick={isDisabled ? undefined : onPress} disabled={isDisabled}>
			<div className={s.hitArea} />
			<div className={s.overlay} />
			{labelToIconComponentTry(label)?.({}) ?? label}
		</button>
	);
};
