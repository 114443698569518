import Prando from "prando";

import { NUM_WORDS } from "../data/Constants";
import { TYearMonthDay, getNthDayOfWeekOfMonth, weekdayNameToNumber } from "../utils/DateUtils";
import { quickHash } from "../utils/StringUtils";

// TODO: we use raw-loader, which is deprecated. We should use asset modules if/when we eject.
import gamesFile from "!!raw-loader!./games.csv";

type TGame = {
	hash: string;
	name: string;
	words: string[];
	date?: string;
};

/**
 * Based on the date param from the list of games, parse into a valid date for a specific year.
 *
 * Accepted formats:
 *   - nnnn => Month (starting at 1) and day in the format "mmdd", for a date of the year.
 *   - nnnnnnnn => Year, month (starting at 1) and day in the format "yyyymmdd", for a specific day.
 *   - weekday:* => a day of the week in a month, in the format "weekday:month:weekday:index", e.g.:
 *     - "weekday:11:thu:4" for the 4th Thursday of November
 *     - "weekday:1:mon:-1" for the last Monday of January
 *
 * TODO: somehow, keep the order of games that should come in a sequence
 */
const parseDate = (dateParam: string, forYear: number): TYearMonthDay => {
	if (dateParam.match(/^\d{4}$/)) {
		const mm = parseInt(dateParam.substring(0, 2), 10);
		const dd = parseInt(dateParam.substring(2, 4), 10);
		return [forYear, mm, dd];
	} else if (dateParam.match(/^\d{6}}$/)) {
		const yyyy = parseInt(dateParam.substring(0, 4), 10);
		const mm = parseInt(dateParam.substring(4, 6), 10);
		const dd = parseInt(dateParam.substring(6, 8), 10);
		return [yyyy, mm, dd];
	}

	const params = dateParam.split(":");
	if (params[0] === "weekday" && params.length === 4) {
		const month = parseInt(params[1], 10) - 1;
		const weekday = params[2];
		const index = parseInt(params[3], 10);
		return getNthDayOfWeekOfMonth(forYear, month, weekdayNameToNumber(weekday), index < 0 ? index : index - 1);
	}

	throw new Error(`Date param "${dateParam}" could not be parsed into a valid date.`);
};

const parseCSVLine = (line: string): string[] => {
	if (line.indexOf('"') === -1) {
		// Simple line
		return line.split(",");
	}
	// Need to parse each field
	const results = [];
	let current = "";
	let quoted = false;
	for (const letter of line.split("")) {
		if (letter === '"') {
			quoted = !quoted;
		} else if (letter === "," && !quoted) {
			results.push(current);
			current = "";
		} else {
			current += letter;
		}
	}
	results.push(current);

	return results;
};

/**
 * Reads games.csv into a list of "games", each with a name, its words, and the date it is scheduled for.
 */
const readGames = (): TGame[] => {
	return gamesFile
		.split("\n")
		.filter((line) => line.length >= 5 && !line.trim().startsWith("#"))
		.map((line) => parseCSVLine(line.trim()))
		.filter((cells) => cells.length >= 7)
		.map((cells) => {
			const words = cells.slice(1, NUM_WORDS + 1);
			return {
				hash: quickHash(words.join(",")),
				name: cells[0],
				words,
				date: cells[NUM_WORDS + 1],
			};
		});
};

const equalsYMD = (day1: TYearMonthDay, day2: TYearMonthDay): boolean => {
	return day1.every((d1, i) => d1 == day2[i]);
};

const shuffledGames = (games: TGame[]): TGame[] => {
	const rng = new Prando(31337);
	const newGames: TGame[] = [];
	const oldGames = games.concat();
	while (oldGames.length > 0) {
		const i = rng.nextInt(0, oldGames.length - 1);
		newGames.push(oldGames[i]);
		oldGames.splice(i, 1);
	}
	return newGames;
};

const games: TGame[] = readGames();
const gamesWithDates: TGame[] = games.filter((g) => !!g.date && g.date.length > 0);
const gamesWithoutDates: TGame[] = shuffledGames(games.filter((g) => !g.date || g.date === ""));
const gameByDateCache: Record<string, TGame> = {};

// TODO: remove this check later
for (let i = 0; i < games.length; i++) {
	for (let j = i + 1; j < games.length; j++) {
		if (games[i].hash === games[j].hash) {
			throw new Error(`Collision between games: ${games[i].name}, ${games[j].name} (${games[i].hash})`);
		}
	}
}

/**
 * Picks a game based on the current day (e.g. "2025-03-18").
 * randomIndex is used as a seed when creating a game without a date.
 */
const pickGame = (dayId: string, randomIndex: number): TGame => {
	if (!gameByDateCache[dayId]) {
		// Find for a game with the specific day (yyyy-mm-dd), then specific day of year (mm-dd), then pick a random one
		const dayYMD = dayId.split("-").map((d) => parseInt(d, 10));
		if (dayYMD.length !== 3) {
			throw new Error("Day id should have 3 components");
		}
		gameByDateCache[dayId] =
			gamesWithDates.find((g) => equalsYMD(parseDate(g.date as string, dayYMD[0]), dayYMD as TYearMonthDay)) ??
			gamesWithoutDates[randomIndex % gamesWithoutDates.length];
	}

	return gameByDateCache[dayId];
};

export default {
	pickGame,
};
