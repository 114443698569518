import { useCallback, useMemo } from "react";

import { cleanupPersistentDataWithPrefix, usePersistentIndexedState } from "./usePersistentState";

// symbol : letter
export type TMapping = Record<string, string>;
export type TUseInputMapping = {
	map: Readonly<TMapping>;
	get: (symbol: string) => string | null;
	set: (symbol: string, letter: string) => void;
	unset: (symbol: string) => void;
	unsetLetter: (letter: string) => void;
	has: (symbol: string) => boolean;
	size: Readonly<number>;
	usedSymbols: readonly string[];
	usedLetters: readonly string[];
};

const DEFAULT_STATE: TMapping = {};

// TODO: remove later
cleanupPersistentDataWithPrefix("game-input-mapping-v");

const useInputMapping = (dayGameId: string): Readonly<TUseInputMapping> => {
	const [mappingState, setMappingState] = usePersistentIndexedState<TMapping>(
		"game-input-mapping",
		dayGameId,
		DEFAULT_STATE,
	);
	const getMapping = useCallback(
		(symbol: string): string => {
			return mappingState[symbol] ?? null;
		},
		[mappingState],
	);

	const setMapping = useCallback(
		(symbol: string, letter: string): void => {
			setMappingState((oldMappingState) => {
				return {
					...oldMappingState,
					[symbol]: letter,
				};
			});
		},
		[setMappingState],
	);

	const unsetMapping = useCallback(
		(symbol: string): void => {
			setMappingState((oldMappingState) => {
				const newMapping = { ...oldMappingState };
				delete newMapping[symbol];
				return newMapping;
			});
		},
		[setMappingState],
	);

	const unsetMappingLetter = useCallback(
		(letter: string): void => {
			setMappingState((oldMappingState) => {
				const newMapping = { ...oldMappingState };
				const symbolUsingLetter = Object.entries(oldMappingState).find(([, l]) => letter === l)?.[0];
				if (symbolUsingLetter) {
					delete newMapping[symbolUsingLetter];
				}
				return newMapping;
			});
		},
		[setMappingState],
	);

	const hasMapping = useCallback(
		(symbol: string): boolean => {
			return Boolean(mappingState[symbol]);
		},
		[mappingState],
	);

	const sizeMapping = useMemo((): number => {
		return Object.keys(mappingState).length;
	}, [mappingState]);

	const usedSymbols = useMemo((): string[] => {
		return Object.keys(mappingState);
	}, [mappingState]);

	const usedLetters = useMemo((): string[] => {
		return Object.values(mappingState);
	}, [mappingState]);

	return useMemo<TUseInputMapping>(() => {
		return {
			map: mappingState,
			get: getMapping,
			set: setMapping,
			unset: unsetMapping,
			unsetLetter: unsetMappingLetter,
			has: hasMapping,
			size: sizeMapping,
			usedSymbols,
			usedLetters,
		};
	}, [mappingState, setMapping, unsetMapping, hasMapping]);
};

export default useInputMapping;
