import React from "react";
import cx from "classnames";

import s from "./MaterialIcons.module.scss";

export type TProps = {
	className?: string;
	onClick?: () => void;
};

export type TMaterialIconComponent = (props: TProps) => JSX.Element | null;

const getProps = (props: TProps): TProps => {
	const className = cx(props.className, props.onClick ? s.clickable : undefined);
	return {
		...props,
		className,
	};
};

// Most icons come from https://fonts.google.com/icons
// Then copy the icon (as SVG)
// At default outline settings (24px, 400 weight, 0 grade)

const Backspace = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="m456-320 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 160q-19 0-36-8.5T296-192L80-480l216-288q11-15 28-23.5t36-8.5h440q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H360ZM180-480l180 240h440v-480H360L180-480Zm400 0Z" />
		</svg>
	);
};

const Bomb = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M346-48q-125 0-212.5-88.5T46-350q0-125 86.5-211.5T344-648h13l27-47q12-22 36-28.5t46 6.5l30 17 5-8q23-43 72-56t92 12l35 20-40 69-35-20q-14-8-30.5-3.5T570-668l-5 8 40 23q21 12 27.5 36t-5.5 45l-27 48q23 36 34.5 76.5T646-348q0 125-87.5 212.5T346-48Zm0-80q91 0 155.5-64.5T566-348q0-31-8.5-61T532-466l-26-41 42-72-104-60-42 72h-44q-94 0-163.5 60T125-350q0 92 64.5 157T346-128Zm454-480v-80h120v80H800ZM580-828v-120h80v120h-80Zm195 81-56-56 85-85 56 56-85 85ZM346-348Z" />
		</svg>
	);
};

const Close = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
		</svg>
	);
};

const ExpandCircleDown = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="m480-340 180-180-57-56-123 123-123-123-57 56 180 180Zm0 260q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
		</svg>
	);
};

const Info = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
		</svg>
	);
};

const Inventory = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z" />
		</svg>
	);
};

const InvertColors = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M480-120q-133 0-226.5-92.5T160-436q0-66 25-122t69-100l226-222 226 222q44 44 69 100t25 122q0 131-93.5 223.5T480-120Zm0-80v-568L310-600q-35 33-52.5 74.5T240-436q0 97 70 166.5T480-200Z" />
		</svg>
	);
};

const BugReport = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M480-200q66 0 113-47t47-113v-160q0-66-47-113t-113-47q-66 0-113 47t-47 113v160q0 66 47 113t113 47Zm-80-120h160v-80H400v80Zm0-160h160v-80H400v80Zm80 40Zm0 320q-65 0-120.5-32T272-240H160v-80h84q-3-20-3.5-40t-.5-40h-80v-80h80q0-20 .5-40t3.5-40h-84v-80h112q14-23 31.5-43t40.5-35l-64-66 56-56 86 86q28-9 57-9t57 9l88-86 56 56-66 66q23 15 41.5 34.5T688-640h112v80h-84q3 20 3.5 40t.5 40h80v80h-80q0 20-.5 40t-3.5 40h84v80H688q-32 56-87.5 88T480-120Z" />
		</svg>
	);
};

const Lightbulb = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-200v-80h320v80H320Zm10-120q-69-41-109.5-110T180-580q0-125 87.5-212.5T480-880q125 0 212.5 87.5T780-580q0 81-40.5 150T630-320H330Zm24-80h252q45-32 69.5-79T700-580q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 54 24.5 101t69.5 79Zm126 0Z" />
		</svg>
	);
};

const RestartAlt = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z" />
		</svg>
	);
};

const Rule = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="m576-160-56-56 104-104-104-104 56-56 104 104 104-104 56 56-104 104 104 104-56 56-104-104-104 104Zm79-360L513-662l56-56 85 85 170-170 56 57-225 226ZM80-280v-80h360v80H80Zm0-320v-80h360v80H80Z" />
		</svg>
	);
};

const Settings = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z" />
		</svg>
	);
};

const Share = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z" />
		</svg>
	);
};

const ShareIos = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M240-40q-33 0-56.5-23.5T160-120v-440q0-33 23.5-56.5T240-640h120v80H240v440h480v-440H600v-80h120q33 0 56.5 23.5T800-560v440q0 33-23.5 56.5T720-40H240Zm200-280v-447l-64 64-56-57 160-160 160 160-56 57-64-64v447h-80Z" />
		</svg>
	);
};

const CopyToClipboard = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" />
		</svg>
	);
};

const Checklist = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 -960 960 960" width="24px" height="24px" fill="currentColor" {...getProps(props)}>
			<path d="M222-200 80-342l56-56 85 85 170-170 56 57-225 226Zm0-320L80-662l56-56 85 85 170-170 56 57-225 226Zm298 240v-80h360v80H520Zm0-320v-80h360v80H520Z" />
		</svg>
	);
};

// https://en.wikipedia.org/wiki/File:Bluesky_Logo.svg
// Original: #1185fe
const SocialMediaBlueSky = (props: TProps): JSX.Element => {
	return (
		<svg viewBox="0 0 600 530" width="30px" height="30px" fill="#1185fe" {...getProps(props)}>
			<path d="M135.72 44.03C202.216 93.951 273.74 195.17 300 249.49c26.262-54.316 97.782-155.54 164.28-205.46C512.26 8.009 590-19.862 590 68.825c0 17.712-10.155 148.79-16.111 170.07-20.703 73.984-96.144 92.854-163.25 81.433 117.3 19.964 147.14 86.092 82.697 152.22-122.39 125.59-175.91-31.511-189.63-71.766-2.514-7.38-3.69-10.832-3.708-7.896-.017-2.936-1.193.516-3.707 7.896-13.714 40.255-67.233 197.36-189.63 71.766-64.444-66.128-34.605-132.26 82.697-152.22-67.108 11.421-142.55-7.45-163.25-81.433C20.15 217.613 9.997 86.535 9.997 68.825c0-88.687 77.742-60.816 125.72-24.795z" />
		</svg>
	);
};

export default {
	Backspace,
	Bomb,
	Close,
	ExpandCircleDown,
	Info,
	Inventory,
	InvertColors,
	BugReport,
	Lightbulb,
	RestartAlt,
	Rule,
	Settings,
	Share,
	ShareIos,
	CopyToClipboard,
	Checklist,
	SocialMediaBlueSky,
};
