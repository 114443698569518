import React from "react";
import cx from "classnames";

import { TMaterialIconComponent } from "./MaterialIcons";

import s from "./Button.module.scss";

export type TProps = {
	className?: string;
	disabled?: boolean;
	icon?: TMaterialIconComponent;
	text?: string;
	onClick?: () => void;
};

// eslint-disable-next-line
export const Button = ({ className, disabled, icon: Icon, text, onClick }: TProps): JSX.Element | null => {
	return (
		<button className={cx(s.main, className)} onClick={disabled ? undefined : onClick} disabled={disabled}>
			{Icon ? <Icon className={s.icon} /> : null}
			{text ? <div className={s.text}>{text}</div> : null}
		</button>
	);
};
