import React from "react";
import cx from "classnames";

import MaterialIcons from "./MaterialIcons";
import { TGeneralScore } from "../hooks/useScore";
import { Logo } from "./Logo";

import s from "./SideMenu.module.scss";

export type TProps = {
	visible?: boolean;
	onClose?: () => void;
	generalScore: TGeneralScore;
	onClickInstructions?: () => void;
	onClickArchive?: () => void;
	onClickFeedback?: () => void;
	onClickSettings?: () => void;
	onClickChangelog?: () => void;
};

export const SideMenu = ({
	visible,
	onClose,
	onClickInstructions,
	onClickArchive,
	onClickFeedback,
	onClickSettings,
	onClickChangelog,
}: TProps): JSX.Element => {
	const classes = cx(s.main, visible ? s.visible : undefined);
	return (
		<div className={classes}>
			<div className={s.overlay} onClick={onClose} />
			<div className={s.content}>
				<div className={s.header}>
					<div className={s.logoArea}>
						<Logo />
					</div>
					<MaterialIcons.Close className={s.closeButton} onClick={onClose} />
				</div>
				<div className={s.menu}>
					<div className={s.menuItem} onClick={onClickInstructions}>
						<MaterialIcons.Info />
						<div>Instructions</div>
					</div>
					<div className={s.menuItem} onClick={onClickArchive}>
						<MaterialIcons.Inventory />
						<div>Puzzle Archive</div>
					</div>
					<div className={s.menuItem} onClick={onClickSettings}>
						<MaterialIcons.Settings />
						<div>Settings</div>
					</div>
					<div className={s.menuItem} onClick={onClickChangelog}>
						<MaterialIcons.Checklist />
						<div>Version History</div>
					</div>
					<div className={s.menuItem} onClick={onClickFeedback}>
						<MaterialIcons.BugReport />
						<div>Report a Bug</div>
					</div>
					<div className={s.menuItem}>
						<MaterialIcons.Lightbulb />
						<div>Contribute</div>
					</div>
				</div>
				<div className={s.footer}>
					<div className={s.title}>QUESTIONS OR COMMENTS</div>
					<div className={s.logos}>
						<a href={"https://bsky.app/profile/iconfound.bsky.social"} target={"_blank"}>
							<MaterialIcons.SocialMediaBlueSky />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
