import React, { useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import Prando from "prando";

import { Symbol } from "./Symbol";
import { TSymbolStyle } from "../hooks/useSymbols";

import s from "./FieldCell.module.scss";

export type TProps = {
	disabled?: boolean;
	reveal?: boolean;
	symbol: string;
	symbolStyle: TSymbolStyle;
	enteredLetter?: string;
	solutionLetter: string;
	focused?: boolean;
	similarToFocused?: boolean;
	right?: boolean;
	wrong?: boolean;
	hasBomb?: boolean;
	onPress?: () => void;
};

type TLabel = string | TSymbolStyle;

enum Mode {
	None,
	Hiding,
	Revealing,
}

export const FieldCell = ({
	disabled,
	reveal,
	symbol,
	symbolStyle,
	enteredLetter,
	solutionLetter,
	focused,
	similarToFocused,
	right,
	wrong,
	hasBomb,
	onPress,
}: TProps): JSX.Element => {
	const [mode, setMode] = useState<Mode>(Mode.None);
	const label = useMemo((): TLabel => {
		if (reveal) {
			return solutionLetter;
		} else if (enteredLetter) {
			return enteredLetter;
		} else {
			return symbolStyle;
		}
	}, [reveal, solutionLetter, enteredLetter, symbol, symbolStyle]);

	const rng = useMemo(() => {
		const rng = new Prando(solutionLetter);
		rng.skip(4);
		return rng;
	}, [solutionLetter]);

	const [shownLabel, setShownLabel] = useState<TLabel>(label);

	useEffect(() => {
		if (shownLabel !== label) {
			setMode(Mode.Hiding);
		}
	}, [label, shownLabel]);

	const handleAnimationEnd = useCallback(() => {
		if (mode === Mode.Hiding) {
			setMode(Mode.Revealing);
			setShownLabel(label);
		} else if (mode === Mode.Revealing) {
			setMode(Mode.None);
		}
	}, [mode]);

	const isHiding = mode === Mode.Hiding;
	const isRevealing = mode === Mode.Revealing;

	const classes = cx(
		s.main,
		focused ? s.focused : undefined,
		similarToFocused ? s.similarToFocused : undefined,
		right ? s.rightCell : undefined,
		wrong || (reveal && !right) ? s.wrongCell : undefined,
		disabled ? s.disabled : undefined,
		isHiding ? s.cellHiding : undefined,
		isRevealing ? s.cellRevealing : undefined,
	);

	const animationClasses = cx(s.animation, s.animating);

	const bombStyle = useMemo(() => {
		return {
			transform: `rotate(${Math.round(rng.next() * 40) - 20}deg)`,
		};
	}, [rng]);

	const explosionStyle = useMemo(() => {
		return {
			transform: `rotate(${Math.round(rng.next() * 360)}deg)`,
		};
	}, [rng]);

	return (
		<div className={classes} onClick={disabled ? undefined : onPress} onAnimationEnd={handleAnimationEnd}>
			<div className={s.background} />
			<div className={s.border} style={{ outlineColor: symbolStyle.color }} />
			<div className={s.hitArea} />
			<div className={s.label} style={{ color: symbolStyle.color }}>
				{typeof shownLabel === "string" ? shownLabel : <Symbol style={shownLabel} />}
			</div>
			{hasBomb ? (
				<div className={animationClasses}>
					<div className={s.bomb} style={bombStyle}>
						{"💣"}
					</div>
					<div className={s.explosion} style={explosionStyle}>
						{"💥"}
					</div>
				</div>
			) : null}
		</div>
	);
};
