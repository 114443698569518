import React, { useCallback, useMemo, useState } from "react";
import cx from "classnames";

import { CURRENT_VERSION_ID, HISTORY, HISTORY_IDS } from "../data/Changelog";
import MaterialIcons from "./MaterialIcons";
import useVersionInfo from "../hooks/useVersionInfo";

import s from "./TextChangelog.module.scss";

export type TProps = {
	className?: string;
};

const labelFromDate = (dateId: string): string => {
	const ids = dateId.split("-");
	const date = new Date(parseInt(ids[0]), parseInt(ids[1]) - 1, parseInt(ids[2]));
	return date.toLocaleDateString();
};

export const DateEntry = ({ id, lines, isLast }: { id: string; lines: string[]; isLast?: boolean }): JSX.Element => {
	return (
		<div className={s.version}>
			<p className={s.date}>
				{labelFromDate(id)}
				{isLast ? " (Last version ran)" : null}
			</p>
			<ul>
				{lines.map((line, i) => (
					<li key={i}>{line}</li>
				))}
			</ul>
		</div>
	);
};

export const TextChangelog = ({ className }: TProps): JSX.Element => {
	const [shouldShowOldVersions, setShouldShowOldVersions] = useState(false);
	const historyIds = useMemo(() => {
		const ids = HISTORY_IDS.concat();
		ids.reverse();
		return ids;
	}, [HISTORY_IDS]);

	const { previousSessionVersion, currentSessionVersion } = useVersionInfo();
	const needsRelativeVersions =
		previousSessionVersion && previousSessionVersion < (currentSessionVersion ?? CURRENT_VERSION_ID);

	const [newVersions, oldVersions] = useMemo<[string[], string[]]>(() => {
		if (needsRelativeVersions) {
			return [
				historyIds.filter((id) => id > previousSessionVersion),
				historyIds.filter((id) => id <= previousSessionVersion),
			];
		} else {
			return [historyIds, []];
		}
	}, [previousSessionVersion, needsRelativeVersions, historyIds]);

	const handleClickOldVersions = useCallback(() => {
		setShouldShowOldVersions((oldValue) => !oldValue);
	}, []);

	return (
		<div className={className}>
			<p>This lists all updates done to the game so far, including fixes and additions.</p>
			{newVersions.map((id) => (
				<DateEntry key={id} id={id} lines={HISTORY[id]} />
			))}
			{oldVersions.length > 0 ? (
				<>
					<p className={s.oldVersionsToggle} onClick={handleClickOldVersions}>
						{"Updates from previous versions "}
						<MaterialIcons.ExpandCircleDown
							className={cx(s.closeButton, shouldShowOldVersions ? s.expanded : s.contracted)}
						/>
					</p>
					<div className={cx(s.oldVersions, shouldShowOldVersions ? s.oldVersionsShown : s.oldVersionsHidden)}>
						{oldVersions.map((id) => (
							<DateEntry key={id} id={id} lines={HISTORY[id]} isLast={id === previousSessionVersion} />
						))}
					</div>
				</>
			) : null}
		</div>
	);
};
