export const HISTORY: Record<string, string[]> = {
	"2025-03-30": [
		"Added: play confetti animation when winning a game",
		'Changed: updated style and size for action buttons ("Hint" and "Check") again, with better contrast on light mode',
		"Changed: updated games/words data (updated 1 game)",
		"Removed: cleaned up console logs (including removing a printout of the solution)",
	],
	"2025-03-24": [
		'Added: new "Reset" button to remove unchecked or incorrect guesses',
		'Fixed: layout doesn\'t change after expanding the "Version History" page to see information about previous versions',
		"Changed: better visual style for scrollbars where needed",
		"Changed: updated some internal library dependencies to more recent versions",
		'Changed: using a more visible symbol for "Backspace" on keyboard',
		'Changed: updated style and size for action buttons ("Hint" and "Check")',
		"Changed: end game message sits atop the keyboard now, with more space",
		"Changed: updated games/words data (updated 1 game, removed 1 game, added 2 games)",
	],
	"2025-03-22": [
		"Added: play a bomb animation over letters when using a Hint",
		"Fixed: prevent edge case crash bug when generating sharing message",
	],
	"2025-03-21": [
		'Changed: updated the design of the "Settings" screen',
		"Changed: all game-specific saved state is now based on a hash, rather than a numeric index; data doesn't need to be reset every time we add, remove, or update games (NOTE: resets saved state one last time)",
		"Changed: updated games/words data (updated 2 games, added 1 game)",
	],
	"2025-03-18": [
		"Fixed: dates in the changelog show the correct month",
		"Changed: games/words can be attributed to relative weekdays of a month, rather than just fixed days of the month (e.g. Thanksgiving day)",
		"Changed: updated games/words data (NOTE: resets saved state)",
	],
	"2025-03-02": [
		'Changed: "Report a Bug" menu item now has a proper link',
		'Changed: show the "Version History" dialog on start when there are updates',
		"Changed: updated games/words data (NOTE: resets saved state)",
	],
	"2025-02-15": ["First version"],
};

export const HISTORY_IDS: string[] = Object.keys(HISTORY).sort();
export const CURRENT_VERSION_ID = HISTORY_IDS[HISTORY_IDS.length - 1];
