import { useMemo } from "react";

import Games from "../data/Games";
import { uniqueLetters } from "../utils/AlphabetUtils";

export type TSolution = {
	name: string;
	words: string[];
	hash: string;
	// From 5 to 25 (5 words) or 6 to 30 (6 words)
	minDifficulty: number;
	maxDifficulty: number;
	difficulty: number;
};

// TODO: move this to a separate report module
// Difficulty for solution is ${stats.difficulty} (of ${stats.minDifficulty}...${stats.maxDifficulty}), with ${stats.numSoloLetters} solo letters.
const getSolutionStats = (
	words: string[],
): { minDifficulty: number; maxDifficulty: number; difficulty: number; numSoloLetters: number } => {
	const uniqueLettersChosen = uniqueLetters(words);
	const wordLetters = words.join("").split("");
	const minDifficulty = words[0].length;
	const maxDifficulty = minDifficulty * words.length;
	return {
		minDifficulty,
		maxDifficulty,
		difficulty: uniqueLettersChosen.length,
		numSoloLetters: uniqueLettersChosen.map((l) => wordLetters.filter((ll) => l === ll).length).filter((c) => c === 1)
			.length,
	};
};

/**
 * This contains the solution for the day (based on the day seed): the selected words, their category and assumed difficulty.
 */
const useSolution = (dayIndex: number, dayId: string): TSolution => {
	const solution = useMemo<TSolution>(() => {
		// TODO: proper solution for picking words
		// TODO: maybe also judge by allowing more repeated letters
		const { name, words, hash } = Games.pickGame(dayId, dayIndex);

		const stats = getSolutionStats(words);

		return {
			name,
			words,
			hash,
			minDifficulty: stats.minDifficulty,
			maxDifficulty: stats.maxDifficulty,
			difficulty: stats.difficulty,
		};
	}, [dayId, dayIndex]);

	return solution;
};

export default useSolution;
