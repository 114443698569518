import React, { ChangeEvent, useCallback, useMemo } from "react";

import { KeyboardLayoutId, KeyboardLayouts } from "../data/KeyboardLayout";
import useSettings, { LightMode } from "../hooks/useSettings";

import s from "./TextSettings.module.scss";

enum HighContrastMode {
	Disabled = "disabled",
	Enabled = "enabled",
}

export type TProps = {
	className?: string;
};

export const TextSettings = ({ className }: TProps): JSX.Element => {
	const settings = useSettings();

	const handleChangeLightMode = useCallback(
		(e: ChangeEvent<HTMLSelectElement>) => {
			const newValue = e.target.value as unknown as LightMode;
			settings.setLightMode(newValue);
		},
		[settings.setLightMode],
	);
	const handleChangeHighContrast = useCallback(
		(e: ChangeEvent<HTMLSelectElement>) => {
			const newValue = e.target.value as unknown as HighContrastMode;
			settings.setHighContrast(newValue === HighContrastMode.Enabled);
		},
		[settings.setHighContrast],
	);
	const handleChangeKeyboardLayout = useCallback(
		(e: ChangeEvent<HTMLSelectElement>) => {
			const newValue = e.target.value as unknown as KeyboardLayoutId;
			settings.setKeyboardLayout(newValue);
		},
		[settings.setKeyboardLayout],
	);

	const highContrastOption = useMemo(() => {
		return settings.highContrast ? HighContrastMode.Enabled : HighContrastMode.Disabled;
	}, [settings.highContrast]);

	return (
		<div className={className}>
			<div className={s.row}>
				<div className={s.labelCell}>COLOR PALETTE</div>
				<div className={s.valueCell}>
					<div className={s.selectWrapper}>
						<select className={s.selectField} onChange={handleChangeLightMode} value={settings.lightMode}>
							<option value={LightMode.Auto}>Auto</option>
							<option value={LightMode.AlwaysDark}>Always Dark</option>
							<option value={LightMode.AlwaysLight}>Always Light</option>
						</select>
					</div>
				</div>
			</div>
			<div className={s.row}>
				<div className={s.labelCell}>High Contrast</div>
				<div className={s.valueCell}>
					<div className={s.selectWrapper}>
						<select className={s.selectField} onChange={handleChangeHighContrast} value={highContrastOption}>
							<option value={HighContrastMode.Disabled}>Disabled</option>
							<option value={HighContrastMode.Enabled}>Enabled</option>
						</select>
					</div>
				</div>
			</div>
			<div className={s.row}>
				<div className={s.labelCell}>Keyboard</div>
				<div className={s.valueCell}>
					<div className={s.selectWrapper}>
						<select className={s.selectField} value={settings.keyboardLayout} onChange={handleChangeKeyboardLayout}>
							{Object.entries(KeyboardLayouts).map(([k, v]) => (
								<option id={k} key={k}>
									{v.name}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
		</div>
	);
};
