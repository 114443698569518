import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import Prando from "prando";

import { Confetti } from "./Confetti";

import s from "./Celebration.module.scss";

const NUM_CONFETTI_GROUPS = 10;

export type TProps = {
	className?: string;
	won?: boolean;
};

export const Celebration = ({ className, won }: TProps): JSX.Element | null => {
	const [isPlaying, setIsPlaying] = useState(false);

	const rng = useMemo(() => {
		return new Prando(new Date().getDay());
	}, []);

	const groups = useMemo(() => {
		rng.reset();
		rng.skip(20);
		return new Array(NUM_CONFETTI_GROUPS).fill(0).map((v, i) => {
			rng.skip(20);
			return {
				x: rng.next(),
				y: rng.next(0, 0.75),
				fallDistance: rng.next(0.75, 1.5),
				delay: i * 0.03,
			};
		});
	}, [rng]);

	useEffect(() => {
		if (won) {
			setIsPlaying(true);
		} else {
			setIsPlaying(false);
		}
	}, [won]);

	if (!won) {
		return null;
	}

	return (
		<div className={cx(s.main, className)}>
			{groups.map((p, i) => {
				return <Confetti key={i} x={p.x} y={p.y} fallDistance={p.fallDistance} delay={p.delay} isPlaying={isPlaying} />;
			})}
		</div>
	);
};
