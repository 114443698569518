import React from "react";

import { FieldCell } from "./FieldCell";
import { TSymbolList, TSymbolStyleMap } from "../hooks/useSymbols";
import { TUseGuesses } from "../hooks/useGuesses";

import s from "./FieldRow.module.scss";

export type TProps = {
	disabled?: boolean;
	getLetterForSymbol: (symbol: string) => string | null;
	symbols: TSymbolList;
	symbolStyles: TSymbolStyleMap;
	word: string;
	reveal?: boolean;
	guesses: Readonly<TUseGuesses>;
	currentPosition?: number;
	currentSymbol?: string;
	hasBomb?: boolean;
	bombCols?: number[];
	onPress?: (col: number) => void;
};

export const FieldRow = ({
	disabled,
	getLetterForSymbol,
	symbols,
	symbolStyles,
	word,
	reveal,
	guesses,
	currentPosition,
	currentSymbol,
	bombCols,
	onPress,
}: TProps): JSX.Element => {
	return (
		<div className={s.main}>
			{symbols.map((symbol, col) => {
				const letter = word.split("")[col];
				const enteredLetter = getLetterForSymbol(symbol);
				const isRight = guesses.right.includes(symbol) && enteredLetter === letter;
				const isWrong =
					(guesses.right.includes(symbol) && enteredLetter && enteredLetter !== letter) ||
					guesses.wrong.some(([s, l]) => s === symbol && l === enteredLetter);
				return (
					<FieldCell
						key={`cell-${col}`}
						disabled={disabled}
						reveal={reveal}
						symbol={symbol}
						symbolStyle={symbolStyles[symbol]}
						enteredLetter={enteredLetter ?? undefined}
						solutionLetter={letter}
						right={isRight}
						wrong={isWrong}
						hasBomb={bombCols?.includes(col)}
						onPress={() => onPress?.(col)}
						focused={currentPosition === col}
						similarToFocused={Boolean(currentSymbol) && currentSymbol === symbol}
					/>
				);
			})}
		</div>
	);
};
