export type TYearMonthDay = [number, number, number];

const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

/**
 * Converts a day of the week string to a number (e.g. "sun" or "Sunday" returns 0)
 */
export const weekdayNameToNumber = (name: string): number => {
	const pos = weekdays.findIndex((wd) => name === wd || name.toLowerCase() == wd.substring(0, name.length));
	if (pos < 0) {
		throw new Error(`Weekday of "${name}" is invalid`);
	} else {
		return pos;
	}
};

/**
 * Returns the number of days in a specific month (same as the last day of the month)
 */
export const getNumDaysInMonth = (year: number, month: number): number => {
	const d = new Date(year, month + 1, 0);
	return d.getDate();
};

/**
 * Based on a month, a weekday, and an index, returns the expected date.
 *
 * Month starts at 0, weekday starts at Sunday (0), index starts at 0
 */
export const getNthDayOfWeekOfMonth = (year: number, month: number, weekday: number, index: number): TYearMonthDay => {
	const boundaryDay = index < 0 ? getNumDaysInMonth(year, month) : 1;
	const d = new Date(year, month, boundaryDay);
	const boundaryWeekdayOfMonth = d.getDay();
	if (index >= 0) {
		// From the start
		const firstDesiredWeekday = (weekday - boundaryWeekdayOfMonth + 7) % 7;
		return [year, month, boundaryDay + firstDesiredWeekday + index * 7];
	} else {
		// From the end
		const firstDesiredWeekday = ((weekday - boundaryWeekdayOfMonth + 6) % 7) + 1;
		return [year, month, boundaryDay + firstDesiredWeekday + index * 7];
	}
};
