import { START_DATE_TIME } from "../data/Constants";
import Games from "../data/Games";

export const nowUTC = (): number => {
	const now = new Date();
	return Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
};

export const dateToId = (date: Date): string => {
	return date.toISOString().substring(0, 10);
};

export const dateToIndex = (dateUTC: number): number => {
	const diffTime = dateUTC - START_DATE_TIME;
	const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24);
	return diffDays;
};

export const indexToDate = (index: number): Date => {
	const dateTimeUTC = new Date(START_DATE_TIME + index * 24 * 60 * 60 * 1000);
	const dateTime = new Date(dateTimeUTC.getUTCFullYear(), dateTimeUTC.getUTCMonth(), dateTimeUTC.getUTCDate());
	return new Date(dateTime);
};

export const createDayGameId = (year: number, gameHash: string): string => {
	return `${year}:${gameHash}`;
};

const indexToDayGameIdCache: Record<number, string> = {};

export const indexToDayGameId = (index: number): string => {
	if (!indexToDayGameIdCache[index]) {
		const date = indexToDate(index);
		const game = Games.pickGame(dateToId(date), index);
		indexToDayGameIdCache[index] = createDayGameId(date.getFullYear(), game.hash);
	}

	return indexToDayGameIdCache[index];
};
