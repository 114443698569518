import { useCallback, useMemo } from "react";

import { cleanupPersistentDataWithPrefix, usePersistentState } from "./usePersistentState";

type TVersionInfo = {
	hasSeenInstructions: boolean;
	currentSessionVersion: string | null;
	previousSessionVersion: string | null;
};

export type TUseVersionInfo = {
	hasSeenInstructions: Readonly<TVersionInfo["hasSeenInstructions"]>;
	currentSessionVersion: Readonly<TVersionInfo["currentSessionVersion"]>;
	previousSessionVersion: Readonly<TVersionInfo["previousSessionVersion"]>;
	setHasSeenInstructions: (hasSeen: boolean) => void;
	setCurrentSessionVersion: (versionId: string) => void;
	setPreviousSessionVersion: (versionId: string) => void;
};

const DEFAULT_STATE: TVersionInfo = {
	hasSeenInstructions: false,
	currentSessionVersion: null,
	previousSessionVersion: null,
};

// TODO: remove later
cleanupPersistentDataWithPrefix("game-seen-instructions-v");

/**
 * Controls what guesses (symbols:letter matching) the user has "checked" against so far
 */
const useVersionInfo = (): Readonly<TUseVersionInfo> => {
	const [versionInfo, setVersionInfo] = usePersistentState<TVersionInfo>(`version-info`, DEFAULT_STATE);

	const setHasSeenInstructions = useCallback(
		(newHasSeenInstructions: boolean) => {
			setVersionInfo((oldVersionInfo) => ({
				...oldVersionInfo,
				hasSeenInstructions: newHasSeenInstructions,
			}));
		},
		[setVersionInfo],
	);

	const setCurrentSessionVersion = useCallback(
		(newValue: string) => {
			setVersionInfo((oldVersionInfo) => ({
				...oldVersionInfo,
				currentSessionVersion: newValue,
			}));
		},
		[setVersionInfo],
	);

	const setPreviousSessionVersion = useCallback(
		(newValue: string) => {
			setVersionInfo((oldVersionInfo) => ({
				...oldVersionInfo,
				previousSessionVersion: newValue,
			}));
		},
		[setVersionInfo],
	);

	return useMemo<TUseVersionInfo>(
		() => ({
			...versionInfo,
			setHasSeenInstructions,
			setCurrentSessionVersion,
			setPreviousSessionVersion,
		}),
		[versionInfo, setHasSeenInstructions, setCurrentSessionVersion, setPreviousSessionVersion],
	);
};

export default useVersionInfo;
