import { TDayScore, TGeneralScore } from "../hooks/useScore";
import { TUseSymbols } from "../hooks/useSymbols";
import { isVowel } from "./AlphabetUtils";
import { TSolution } from "../hooks/useSolution";

export const getStars = (numStars: number): string => {
	return new Array(numStars).fill("⭐").join("");
};

/**
 * Whether we should use native share for the share text. This requires:
 * - a browser that supports the share API
 * - a browser whose share API can share text
 * - a mobile device (share on desktop is nonsense)
 * - not Firefox (sharing is sorta fucked up even though it returns as supported)
 *
 * @See: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
 * @See: https://caniuse.com/?search=web%20share
 * @See: https://w3c.github.io/web-share/demos/share-files.html
 */
export const shouldUseNativeShareForText = (): boolean => {
	const supportsShareAPI = Boolean(navigator.canShare);
	const canShareText = Boolean(navigator.canShare?.({ text: "Some text" }));
	const isAndroid = Boolean(navigator.userAgent.match(/Android/i));
	const isIOS = Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i));
	const isMobileDevice = isAndroid || isIOS;
	const isFirefox = Boolean(navigator.userAgent.match(/Firefox/i));
	return supportsShareAPI && canShareText && isMobileDevice && !(isAndroid && isFirefox);
};

export const shouldUseAppleShareIcon = (): boolean => {
	return Boolean(navigator.userAgent.match(/Macintosh|iPhone|iPad|iPod/i));
};

export const shareNatively = (text: string): Promise<void> => {
	return navigator.share({ text });
};

export const shareViaClipboard = (text: string): void => {
	navigator.clipboard.writeText(text);
};

export const createShareText = (
	dayIndex: number,
	isGameDay: boolean,
	solution: TSolution,
	dayScore: TDayScore,
	generalScore: TGeneralScore,
	symbols: TUseSymbols,
): string => {
	if (!dayScore.hasWon && dayScore.chancesLeft > 0) {
		return "Game not finished";
	}

	// For each symbol, find when it was checked correctly
	const lines = [];

	// Title
	lines.push(`Iconfound.me #${dayIndex + 1}: ${solution.name}`);
	if (dayScore.hasWon) {
		lines.push(getStars(dayScore.chancesLeft));
	} else {
		lines.push("💀");
	}
	lines.push("");

	// Playing field
	const lastChanceUsed = dayScore.chancesUsed[dayScore.chancesUsed.length - 1];
	lines.push(
		symbols.field.map((row) =>
			row
				.map((symbol) => {
					// A symbol right the first time it was checked: 🟩
					// A symbol wrong once, but ultimately right: 🟨
					// A symbol wrong more than once, but ultimately right: 🟧
					// A symbol wrong or omitted: 🟥
					const wrongTimes = dayScore.chancesUsed.filter((c) => c.wrongs.includes(symbol)).length;
					const eventuallyRight =
						lastChanceUsed && !lastChanceUsed.blanks.includes(symbol) && !lastChanceUsed.wrongs.includes(symbol);
					const useCircle = isVowel(symbols.mapping[symbol]);
					if (eventuallyRight) {
						if (wrongTimes === 0) {
							return useCircle ? "🟢" : "🟩";
						} else if (wrongTimes === 1) {
							return useCircle ? "🟡" : "🟨";
						} else {
							return useCircle ? "🟠" : "🟧";
						}
					}
					return useCircle ? "🔴" : "🟥";
				})
				.join(""),
		),
	);

	// Streak count
	if (isGameDay) {
		lines.push("");
		lines.push(`Streak: ${generalScore.currentWinStreak}`);
	}

	return lines.flat().join("\n");
};
